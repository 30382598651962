a.button {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 27px !important;
  line-height: 32px;
  text-transform: uppercase;
  text-decoration: none;
  color: #b28937;

  background-color: #000000;
  border-radius: 5px;
  padding: 14px 50px;
  border: 2px solid #b28937
}

a.button:hover {
	background-color: #4c4c4c;
  color: #b28937;
  transition-delay: 100ms;
  -webkit-transition: all 500ms ease;
}

@media all and (max-width: 992px) {
  a.button {
    padding: 15px 40px;
  }
}
@media all and (max-width: 576px) {
  a.button {
    padding: 10px 25px;
    font-size: 24px !important;
  }
}